import { useEffect, useState } from 'react';
import Link from 'next/link';
import { AppRoutes } from 'src/lib/routes';
import ImageWithFallback from 'src/components/ImageWithFallback';
import styles from 'src/styles/modules/components/brand_image_grid.module.scss';
import { Option, City } from 'src/types';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import Money from 'src/components/Money';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsStarFill } from 'react-icons/bs';
import { truncate, slice } from 'lodash-es';
import classNames from 'classnames';
import useBreakpoints from 'src/hooks/useBreakpoints';

const CityCard: React.FC<{
  record: City;
  className: string;
  bgClassName: string;
  optionNameLength?: number;
}> = ({ record, className, bgClassName, optionNameLength }) => {
  const title = `${record.name}${record.strippedTopThingsToDo ? `: ${record.strippedTopThingsToDo}` : ''}`
  return (
    <Link legacyBehavior prefetch={false} href={AppRoutes.cityRoute({ id: record.slug }).toPath()}>
      <a className={className}>
        <ImageWithFallback
          record={record.image}
          imgField="webpLgUrl"
          alt=""
          fill
          className="tw-static tw-block tw-object-cover tw-rounded-tr-lg tw-rounded-tl-lg"
        />

        <div
          className={`tw-px-2 md:tw-px-4 tw-py-4 tw-rounded-br-lg tw-rounded-bl-lg ${bgClassName}`}
        >
          <div
            className={classNames(
              `tw-font-styleSans tw-font-semibold tw-text-center ${styles.featureTitle}`
            )}
          >
            {optionNameLength ? truncate(title, { length: optionNameLength }) : title}
          </div>

          <div className="tw-mt-4 tw-text-center">
            <HiOutlineArrowNarrowRight size={24} className="tw-text-brand-navy" />
          </div>
        </div>
      </a>
    </Link>
  );
};

const OptionCard: React.FC<{
  record: Option;
  className: string;
  bgClassName: string;
  optionNameLength?: number;
  isGift?: boolean;
}> = ({ record, className, bgClassName, optionNameLength, isGift }) => {
  const currentCurrency = useCurrentCurrency();
  const minPrice = record.minPrice.price || record.minPrice.masterPrice

  return (
    <Link legacyBehavior prefetch={false} href={AppRoutes.experienceRoute({ id: record.id }, isGift ? { gift_experience: 1 } : {}).toPath()}>
      <a className={className}>
        <ImageWithFallback
          record={record.images[0]}
          imgField="webpLgUrl"
          alt=""
          fill
          className="tw-static tw-block tw-object-cover tw-rounded-tr-lg tw-rounded-tl-lg"
        />

        <div
          className={`tw-px-2 md:tw-px-4 tw-py-4 tw-rounded-br-lg tw-rounded-bl-lg ${bgClassName}`}
        >
          <div className="tw-text-center tw-font-styleSans tw-font-semibold tw-mb-2 md:tw-mb-4 tw-h-10 md:tw-h-auto">
            {record.reviewRating ? (
              <>
                <span>
                  {record.reviewRating}
                  <BsStarFill size={16} className="tw-text-orange tw-align-top tw-ml-0.5 tw-pt-0.5" />
                </span>
                <span className="tw-mx-1">|</span>
              </>
            ) : null}
            <span>
              From{' '}
              <Money
                className="tw-text-orange"
                amount={{
                  amount: Math.ceil(Number(minPrice.amount)),
                  currency: minPrice.currency
                }}
                toCurrency={currentCurrency}
                hasConversionFee={!isGift && record.baseCurrency !== currentCurrency}
              />
            </span>
          </div>

          <div
            className={classNames(
              `tw-font-styleSans tw-font-semibold tw-text-center ${styles.featureTitle}`
            )}
          >
            {optionNameLength ? truncate(record.name, { length: optionNameLength }) : record.name}
          </div>

          <div className="tw-mt-4 tw-text-center">
            <HiOutlineArrowNarrowRight size={24} className="tw-text-brand-navy" />
          </div>
        </div>
      </a>
    </Link>
  );
};

const BrandImageGrid: React.FC<{
  cities?: City[];
  options?: Option[];
  cardModifierClassName?: string;
  isGift?: boolean;
}> = ({ cities, options, isGift, cardModifierClassName = '' }) => {
  const [records, setRecords] = useState<(City | Option)[]>(cities || options || [])
  const Card = (cities ? CityCard : OptionCard) as React.FC<{
    record: Option | City;
    className: string;
    bgClassName: string;
    optionNameLength?: number;
    isGift?: boolean;
  }>;
  const { isScreenMd } = useBreakpoints();

  useEffect(() => {
    if (options && options.length > 0) {
      setRecords(isScreenMd ? options : options.slice(0, 5))
    }
  }, [JSON.stringify(options || []), isScreenMd])

  useEffect(() => {
    if (cities && cities.length > 0) {
      setRecords(isScreenMd ? cities : cities.slice(0, 5))
    }
  }, [JSON.stringify(cities ||[]), isScreenMd])

  return records.length >= 6 ? (
    <div className={`row ${cardModifierClassName}`}>
      <div className="col-6">
        <Card
          isGift={isGift}
          record={records[0]}
          className={`${styles.featureBigImageV2}`}
          bgClassName="tw-bg-brand-green"
        />
        <div className="row tw-pt-3.75 tw-mt-3.75">
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[2]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-grey-lighter"
              optionNameLength={40}
            />
          </div>
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[3]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-purple"
              optionNameLength={40}
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[4]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-grey-lighter"
              optionNameLength={40}
            />
          </div>
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[5]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-brand-yellow"
              optionNameLength={40}
            />
          </div>
        </div>
        <div className="tw-pt-3.75 tw-mt-3.75">
          <Card
            isGift={isGift}
            record={records[1]}
            className={`${styles.featureBigImageV2}`}
            bgClassName="tw-bg-brand-green"
          />
        </div>
      </div>
    </div>
  ) : records.length === 5 ? (
    <div className={`row ${cardModifierClassName}`}>
      <div className="col-md-6">
        <Card
          isGift={isGift}
          record={records[0]}
          className={`${styles.featureBigImageV2}`}
          bgClassName="tw-bg-brand-green"
        />
        <div className="row tw-pt-3.75 tw-mt-3.75">
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[1]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-orange-light md:tw-bg-grey-lighter"
              optionNameLength={40}
            />
          </div>
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[2]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-purple"
              optionNameLength={40}
            />
          </div>
        </div>
        <div className="row tw-pt-3.75 tw-mt-3.75 tw-flex md:tw-hidden">
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[3]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-grey-lighter"
              optionNameLength={40}
            />
          </div>
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[4]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-grey-lightest"
              optionNameLength={40}
            />
          </div>
        </div>
      </div>
      <div className="col-6 tw-hidden md:tw-block">
        <Card
          isGift={isGift}
          record={records[3]}
          className={`${styles.featureSmallBigImageV2}`}
          bgClassName="tw-bg-grey-lightest"
        />
        <div className="tw-pt-3.75 tw-mt-3.75">
          <Card
            isGift={isGift}
            record={records[4]}
            className={`${styles.featureBigImageV2}`}
            bgClassName="tw-bg-orange-light"
          />
        </div>
      </div>
    </div>
  ) : records.length === 4 ? (
    <div className={`row ${cardModifierClassName}`}>
      <div className="col-md-6">
        <Card
          isGift={isGift}
          record={records[0]}
          className={`${styles.featureBigImageV2}`}
          bgClassName="tw-bg-brand-green"
        />
        <div className="tw-pt-3.75 tw-mt-3.75 tw-hidden md:tw-block">
          <Card
            isGift={isGift}
            record={records[2]}
            className={`${styles.featureSmallBigImageV2}`}
            bgClassName="tw-bg-grey-lighter"
          />
        </div>

        <div className="row tw-pt-3.75 tw-mt-3.75 tw-flex md:tw-hidden">
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[1]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-orange-light"
              optionNameLength={40}
            />
          </div>
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[2]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-grey-lightest"
              optionNameLength={40}
            />
          </div>
        </div>

        <div className="tw-pt-3.75 tw-mt-3.75 tw-block md:tw-hidden">
          <Card
            isGift={isGift}
            record={records[3]}
            className={`${styles.featureBigImageV2}`}
            bgClassName="tw-bg-grey-lighter"
          />
        </div>
      </div>
      <div className="col-6 tw-hidden md:tw-block">
        <Card
          isGift={isGift}
          record={records[1]}
          className={`${styles.featureSmallBigImageV2}`}
          bgClassName="tw-bg-grey-lightest"
        />
        <div className="tw-pt-3.75 tw-mt-3.75">
          <Card
            isGift={isGift}
            record={records[3]}
            className={`${styles.featureBigImageV2}`}
            bgClassName="tw-bg-orange-light"
          />
        </div>
      </div>
    </div>
  ) : records.length === 3 ? (
    <div className={`row ${cardModifierClassName}`}>
      <div className="col-md-6">
        <Card
          isGift={isGift}
          record={records[0]}
          className={`${styles.featureSmallBigImageV2}`}
          bgClassName="tw-bg-brand-green"
        />
      </div>
      <div className="col-md-6 tw-pt-3.75 tw-mt-3.75 md:tw-pt-0 md:tw-mt-0">
        <div className="row">
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[1]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-orange-light"
              optionNameLength={40}
            />
          </div>
          <div className="col-6">
            <Card
              isGift={isGift}
              record={records[2]}
              className={`${styles.featureSmallImageV2}`}
              bgClassName="tw-bg-grey-lightest"
              optionNameLength={40}
            />
          </div>
        </div>
      </div>
    </div>
  ) : records.length === 2 ? (
    <div className={`row ${cardModifierClassName}`}>
      <div className="col-6">
        <Card
          isGift={isGift}
          record={records[0]}
          className={`${styles.featureBigSmallImageV2}`}
          bgClassName="tw-bg-brand-green"
          optionNameLength={isScreenMd ? undefined : 40}
        />
      </div>
      <div className="col-6">
        <Card
          isGift={isGift}
          record={records[1]}
          className={`${styles.featureBigSmallImageV2}`}
          bgClassName="tw-bg-orange-light"
          optionNameLength={isScreenMd ? undefined : 40}
        />
      </div>
    </div>
  ) : null;
};

export default BrandImageGrid;
